<template>
  <div>
    <CheckoutBillingAddressForm
      :address="formValues"
      :api-errors
      :countries
      :submitting="loaderStore.isEnabled"
      :with-user-information="userInformationStore.isFormRequired"
      @submit="handleSubmit"
      @submit-failed="handleFailure"
    />

    <ReassuranceItems class="mt-24 md:mt-56">
      <BouyguesReassuranceItems
        v-if="cartStore.bouyguesMobilePlan"
        :benefits="cartStore.bouyguesMobilePlan.benefits"
      />
    </ReassuranceItems>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from '#imports'
import { computed, onMounted, ref } from 'vue'

import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { insertIf } from '@backmarket/utils/collection/insertIf'
import { type ErrorsOf } from '@ds/components/Form'

import ReassuranceItems from '~/scopes/reassurance/components/ReassuranceItems/ReassuranceItems.vue'

import BouyguesReassuranceItems from '../../components/BouyguesReassuranceItems/BouyguesReassuranceItems.vue'
import useHandleUnauthorizedUser from '../../composables/useHandleUnauthorizedUser'
import { CHECKOUT } from '../../routes-names'
import { useAddressStore } from '../../stores/addressStore'
import { useCartStore } from '../../stores/cartStore'
import { useLoaderStore } from '../../stores/loaderStore'
import { useUserInformationStore } from '../../stores/userInformationStore'
import { getAPIErrors } from '../../utils/getAPIErrors'

import type { FormValues } from './components/BillingAddressForm.types'
import CheckoutBillingAddressForm from './components/BillingAddressForm.vue'

const tracking = useTracking()
const logger = useLogger()
const router = useRouter()

const cartStore = useCartStore()
const addressStore = useAddressStore()
const userInformationStore = useUserInformationStore()
const loaderStore = useLoaderStore()

const { openErrorToast } = useTheToast()

const { handleUnauthorizedUser } = useHandleUnauthorizedUser()

const apiErrors = ref<{ [key: string]: string }>({})

const formValues = computed(() => {
  return {
    ...addressStore.billing,
    ...insertIf(userInformationStore.isFormRequired, {
      birthdate: userInformationStore.birthdate || '',
      nationalId: userInformationStore.nationalId,
    }),
  }
})

const countries = computed(() => {
  return [...addressStore.billableCountries]
    .map(({ name, countryCode: billableCountryCode }) => ({
      value: billableCountryCode,
      label: name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
})

onMounted(() => {
  loaderStore.disable()
  tracking.trackFunnel(cartStore.trackingData(CHECKOUT.BILLING_ADDRESS))
})

const handleFailure = (errors: ErrorsOf<Omit<FormValues, 'nationalId'>>) => {
  logger.info('[CHECKOUT] Failed to submit billing form', {
    errors: { fields: Object.keys(errors) },
    owners: ['bot-squad-checkout-front'],
  })
  tracking.trackClick({
    name: '3_billing-new-edit_cta_continue_failed_attempt',
    zone: 'funnel',
  })
}

const handleSubmit = async (values: Omit<FormValues, 'nationalId'>) => {
  apiErrors.value = {}
  loaderStore.enable()

  try {
    const address = {
      ...addressStore.billing,
      ...values,
      countryDialInCode: addressStore.shipping.countryDialInCode,
      phone: addressStore.shipping.phone,
    }

    if (cartStore.hasBouyguesMobilePlan) {
      await addressStore.saveBouyguesAddress({
        address,
        isShipping: false,
        isBilling: true,
      })
    }

    // 1. Save address
    await addressStore.saveAddress({
      address,
      formType: userInformationStore.formType,
      isShipping: false,
      isBilling: true,
    })

    tracking.trackClick({
      name: '3_billing-new-edit_cta_continue',
      zone: 'funnel',
    })

    // 2. Reload checkout store
    await cartStore.fetchCart()
    // 3. Move to next page
    router.push({
      name: CHECKOUT.ADDRESS_CONFIRMATION,
    })
  } catch (error) {
    tracking.trackClick({
      name: '3_billing-new-edit_cta_continue_failed_attempt',
      zone: 'funnel',
    })

    if ((error as Record<string, unknown>)?.status === 400) {
      const errors = getAPIErrors(error as Record<string, unknown>)

      apiErrors.value = errors
      openErrorToast()
    } else
      await handleUnauthorizedUser(
        error as Record<string, unknown>,
        '[CHECKOUT] Failed to submit billing form',
      )

    loaderStore.disable()
  }
}
</script>
